import React from 'react';
import Styles from './Footer.module.scss'
import { Grid, Typography, Box, Divider } from '@mui/material';
import Logo from '../../assets/Images/FooterLogo.png'
import SponcerLogo from '../../assets/Images/SponcersLogo.png'
import { useNavigate } from 'react-router-dom';
import NgLogo from '../../assets/Images/NGLCC_Logo.png'
import LinkedInLogo from "../../assets/Images/LinkedIn.png"

const Footer = () => {

    const navigate = useNavigate()

    const handleNavigate = (path) => {
        navigate(path)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <div className={Styles.container}>
            <Grid container columnSpacing={4} rowSpacing={8} className={Styles.gridContainer}>
                <Grid xs={12} sm={12} md={3} sx={{ display: "flex", flexDirection: "column", gap: "20px" , cursor:"pointer" }} onClick={()=>window.open("https://www.linkedin.com/company/advantedgets/")}>
                    <img src={Logo} alt='/' style={{ height: '60px', width: '220px' }} />
                    <Box sx={{display:"flex",alignItems:"center"}}>
                        <img src={LinkedInLogo} alt="/" style={{
                            height: '28px',
                            width: '28px',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }} />
                        <Box className={Styles.followUs}>
                            <Typography className={Styles.followUsTitle}>Follow us</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={12} sm={4} md={3} className={Styles.quickLinksContainer}>
                    <Typography className={Styles.title}>Quick Links</Typography>
                    <Typography className={Styles.value} onClick={() => handleNavigate('/')}>Home</Typography>
                    <Typography className={Styles.value} onClick={() => handleNavigate('/about')}>About</Typography>
                    <Typography className={Styles.value} onClick={() => handleNavigate('/services')}>Services</Typography>
                    <Typography className={Styles.value} onClick={() => handleNavigate('/contact')}>Contack Us</Typography>
                </Grid>
                <Grid xs={12} sm={4} md={3} className={Styles.addressContainer}>
                    <Box>
                        <Typography className={Styles.title}>Phone</Typography>
                        <Typography className={Styles.value}>+1 734-224-6644</Typography>
                    </Box>
                    <Box>
                        <Typography className={Styles.title}>Address</Typography>
                        <Typography className={Styles.value}>581 State Circle, Ste.A</Typography>
                        <Typography className={Styles.value}>Ann Arbor, MI 48108</Typography>
                    </Box>
                    <Box>
                        <Typography className={Styles.title}>Email</Typography>
                        <Typography className={Styles.value}>info@advantedgets.com</Typography>
                    </Box>
                </Grid>
                <Grid xs={12} sm={4} md={3} className={Styles.sponcerContainer}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }} className={Styles.sponcerBox}>
                        <Typography className={Styles.value}>Certified By</Typography>
                        <img src={NgLogo} alt='/' style={{ height: '70px', width: 'auto' }} />
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ backgroundColor: "#4C5D7C", opacity: 1 }} />
            <Box className={Styles.copyrightContainer}>
                <Typography className={Styles.copyrightMessage}>© Copyright 2024, All Rights Reserved by</Typography>
                <Typography className={Styles.copyrightLink}> AdvantEdge</Typography>
            </Box>
        </div>
    );
};

export default Footer;
